var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        [
          _c("CCardBody", { staticClass: "px-1 pt-3 pb-0" }, [
            _c("div", { staticClass: "d-flex flex-wrap" }, [
              _c("div", { staticClass: "pr-2 mb-sm-0 mb-2" }, [
                _c("h5", { staticClass: "mt-1" }, [
                  _vm._v("Total: "),
                  _c(
                    "span",
                    { staticClass: "d-inline count bg-primary pb-1" },
                    [_vm._v(_vm._s(_vm.total))]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex-grow-1" },
                [
                  _c("CPagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pages > 1,
                        expression: "pages > 1",
                      },
                    ],
                    attrs: {
                      align: "end",
                      dots: false,
                      pages: _vm.pages,
                      "active-page": _vm.activePage,
                    },
                    on: {
                      "update:activePage": function ($event) {
                        _vm.activePage = $event
                      },
                      "update:active-page": function ($event) {
                        _vm.activePage = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          hover: "",
          striped: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "table-filter": { external: true, lazy: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No restaurant types found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "update:table-filter-value": _vm.tableSearch,
          "update:column-filter-value": _vm.columnFilter,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "type_image",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c("CImg", {
                      staticClass: "c-avatar-img",
                      staticStyle: { width: "36px", height: "36px" },
                      attrs: {
                        src: item.type_image_path,
                        placeholderColor: "lightgray",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "show_details",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "primary",
                          variant: "outline",
                          square: "",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleDetails(item, index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(Boolean(item._toggled) ? "Hide" : "Show") +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "details",
            fn: function ({ item, index }) {
              return [
                _c(
                  "CCollapse",
                  {
                    attrs: {
                      show: Boolean(item._toggled),
                      duration: _vm.collapseDuration,
                    },
                  },
                  [
                    _c(
                      "CCardBody",
                      [
                        _c(
                          "CMedia",
                          {
                            attrs: {
                              "aside-image-props": {
                                width: "100%",
                                height: 102,
                                src: item.type_image_path,
                              },
                            },
                          },
                          [
                            _c("h4", [_vm._v(_vm._s(item.type) + " ")]),
                            _c("p", { staticClass: "text-muted" }, [
                              _vm._v(" "),
                            ]),
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  size: "sm",
                                  to: {
                                    name: "Edit Type",
                                    params: { id: item.id },
                                  },
                                  color: "info",
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-align-left" },
                                }),
                                _vm._v(" Edit"),
                              ],
                              1
                            ),
                            _c(
                              "CButton",
                              {
                                staticClass: "ml-1",
                                attrs: { size: "sm", color: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item, index)
                                  },
                                },
                              },
                              [
                                _c("CIcon", { attrs: { name: "cil-trash" } }),
                                _vm._v(" Delete"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("CPagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pages > 1,
            expression: "pages > 1",
          },
        ],
        attrs: {
          pages: _vm.pages,
          "active-page": _vm.activePage,
          align: "center",
        },
        on: {
          "update:activePage": function ($event) {
            _vm.activePage = $event
          },
          "update:active-page": function ($event) {
            _vm.activePage = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }